interface Filters {
    name: string,
    filterItems: Array<FilterItem>
    
}

interface FilterItem {
    propertyName: string,
    value: any 
}

export default function PeopleFinder() {
    const navButton = document.querySelector('.cc-people-finder-button');
    const navMenu = document.querySelector('.cc-people-finder');
    const searchButton = document.querySelector('.cc-people-finder-search');
    const navIcons = document.querySelector('.cc-nav-icons');
    const closeIcon = document.querySelector('.cc-people-finder-close-icon');
    const peopleFinderIcon = document.querySelector('.cc-people-finder-icon');
    const menuButton = document.querySelector('.cc-nav-button');
    const menu = document.querySelector('.cc-nav-menu');
    const header = document.querySelector('header');

    const listSelectors = [
        { "selector": "#productlist", "data": "department" },
        { "selector": "#personlist", "data": "person" },
        { "selector": "#locationlist", "data": "location" }];
    
    const apiUrl = '/api/v1/peoplefinder/';

    const ToggleNav = () => {
        
        const isActive = header?.classList.contains('people-finder-active');
        if (!isActive) {
            navMenu?.classList.remove('hidden');
            closeIcon?.classList.remove('hidden');
            header?.classList.remove('nav-active');

            navIcons?.classList.add('cc-active-change');
            peopleFinderIcon?.classList.add('hidden');
            navButton?.classList.add('is-active');
            header?.classList.add('people-finder-active');

            if (header?.classList.contains('overlay')) {
                header.setAttribute('data-overlay', 'on');
            }
            header?.classList.remove('overlay');
            document.documentElement.classList.add('overflow-y-hidden');

        } else {
            navMenu?.classList.add('hidden');
            closeIcon?.classList.add('hidden');
            header?.classList.remove('nav-active');

            navIcons?.classList.remove('cc-active-change');
            peopleFinderIcon?.classList.remove('hidden');
            navButton?.classList.remove('is-active');
            header?.classList.remove('people-finder-active');

            if (header?.hasAttribute('data-overlay')) {
                header?.classList.add('overlay');
                header.removeAttribute('data-overlay');
            }
            document.documentElement.classList.remove('overflow-y-hidden');

        }

        menuButton?.classList.remove('is-active');
        menu?.classList.add('hidden');
    };

    const PopulateLists = (data: Array<Filters>) => {
        

        listSelectors.forEach(selector => {
            const list = document.querySelector(selector.selector);
            const listData = data.find(list => list.name === selector.data) as Filters;

            listData.filterItems.forEach(filters => {
                const option = document.createElement('option') as HTMLOptionElement;
                option.value = filters.value;
                option.text = filters.propertyName;
                list?.appendChild(option);
            });
           
        });       
    };

    const LoadFilters = () => {
        fetch(`${apiUrl}getconfig`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            data.getAllPages = true;
            return fetch(`${apiUrl}GetFilters`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Accept': 'application/json',
                },
                body: JSON.stringify({ "TotalResults": 0, "CurrentPage": 0, "ListConfig": data, "FilterItems": [] })
            });
        })
        .then(response => response.json())
        .then(data => {
            PopulateLists(data);
        })
        .catch(error => {
            console.error('There has been a problem with your fetch operation:', error);
        });
    };

    const SearchPeople = (e:Event) => {
        e.preventDefault();

        let params = Array<string>();

        listSelectors.every(selector => {

            const selectList = document.querySelector(selector.selector) as HTMLSelectElement;
            const value = selectList.selectedOptions[0].value;

            if (selector.selector === '#personlist' && value) {
                //ignore all other filters
                params = [`${selector.data}/${value}`];
                return false;

            }
            else {
                if (value) {
                    params.push(`${selector.data}/${value}`);
                }
                return true;
            }
            
        });

        location.href = '/people-finder/' + params.join('/');
    }

    LoadFilters();

    navButton?.addEventListener('click', ToggleNav);
    searchButton?.addEventListener('click', SearchPeople);

}