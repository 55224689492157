import gsap from 'gsap';

interface IPersonObj {
    detailElem: HTMLElement;
    row: number;
    tl?: any;
}
/*
    used for both in page people and people finder
 */
export default class PeopleViewer {
    peopleItems: NodeListOf<HTMLElement> | null = null;
    peopleButtons: NodeListOf<HTMLElement> | null = null;
    activePanelId: string | undefined = undefined;
    activeDetailElem: HTMLElement | null = null;
    activeButton: HTMLElement | null = null;
    activeTimeline: any | null = null;
    activeParentElem: HTMLElement | null = null;
    rowHelpers: Record<string, HTMLElement> = {};
    activeRow: number | undefined = undefined;
    peopleObj: Record<string, IPersonObj> = {};
    eventAttached: boolean = false;
    gridRowCount: number = 0;

    constructor(gridRowCount: number) {
        // create necessary elements
        this.gridRowCount = gridRowCount;
        this.initPeopleItems();
    }

    private setDetailHeight(peopleElem: HTMLElement, detailElem: HTMLElement) {

        gsap.to(peopleElem, { marginBottom: detailElem.offsetHeight + 50 });
    }

    private hideRow(peopleElem: HTMLElement | null) {
        if (peopleElem) {
            gsap.to(peopleElem, { marginBottom: 0 });
        }
    }

    private showDetail(detailElem: HTMLElement | null) {
        if (detailElem) {
            const tl = gsap.timeline();
            tl.set(detailElem, { left: 0 })
                .to(detailElem, { opacity: 1, duration: 0.6, delay: 0.5 });
                //.to(detailElem, { marginBottom: detailElem.offsetHeight + 50, duration: 0.6, delay: 0.5 })
        }  
    }

    private hideDetail(detailElem: HTMLElement | null) {
        if (detailElem) {
            const tl = gsap.timeline();
            tl.to(detailElem, { opacity: 0, duration: 0.6, delay: 0.5 })
                .set(detailElem, { left: '-99999px' });
        }
    }

    private checkScreenSize() {
        return window.innerWidth > 1024 ? 'md' : 'sm';
    }

    public reInitizlisePeopleItems() {
        this.peopleObj = {};
        this.initPeopleItems();
    }

    private initPeopleItems() {
        this.peopleItems = document.querySelectorAll('.cc-people-item');

        Array.from(this.peopleItems).forEach((item, i) => {
            const id = item.getAttribute('data-id');
            const row = Math.ceil((i + 1) / this.gridRowCount);
            const detailElem = item.querySelector('.cc-people-item__detail');

            detailElem?.classList.add(`overlay_${this.gridRowCount}_${i % this.gridRowCount}`);

            if (detailElem && id) {
                this.peopleObj[id] = {
                    detailElem: detailElem as HTMLElement,
                    row
                };

                if (this.checkScreenSize() === 'sm') {
                    const tl = gsap.timeline({ paused: true });
                    //tl.from(detailElem, { left: 0 });
                    this.peopleObj[id].tl = tl;
                }
            }

            // check if element is at the start of a row
            const isHelper = i % this.gridRowCount === 0;

            if (isHelper) {
                this.rowHelpers[row] = item as HTMLElement;
            }
        });

        this.setupEventListeners();
    }

    private toggleActive(id: string) {
        // get the related person object
        const { detailElem, row, tl } = this.peopleObj[id];

        if (this.activePanelId) {
            const activePanel = document.querySelector(`.cc-people-item[data-id="${this.activePanelId}"]`)
            activePanel?.querySelector('.cc-plus-icon')?.classList.remove('hidden');
            activePanel?.querySelector('.cc-minus-icon')?.classList.add('hidden');
        }

        if (this.checkScreenSize() === 'md' && this.activePanelId !== undefined && this.activePanelId === id) {
            this.hideDetail(this.activeDetailElem);
            if (this.activeRow) {
                // get the related row helper
                const rowHelper = this.rowHelpers[this.activeRow];
                this.hideRow(rowHelper);
            }
            this.activeDetailElem = null;
            this.activePanelId = undefined;
        } else {
            // check if we're on a new row
            const isNewRow = this.activeRow !== row;

            if (this.activeRow && isNewRow) {
                // get the related row helper
                const rowHelper = this.rowHelpers[this.activeRow];
                this.hideRow(rowHelper);
            }

            // get the active row
            this.activeRow = row;
            // get the row helper element
            const rowHelper = this.rowHelpers[this.activeRow];


            if (this.activeDetailElem) {
                this.hideDetail(this.activeDetailElem);
            }

            if (this.checkScreenSize() === 'md') {
                this.setDetailHeight(rowHelper, detailElem);
            }

            

            this.showDetail(detailElem);

            if (this.checkScreenSize() === 'sm') {
                let parentElem: HTMLElement | null = null;
                let currentElem: HTMLElement | null | undefined = detailElem;
                while (parentElem === null) {
                    if (currentElem?.classList.contains('cc-people-item')) {
                        parentElem = currentElem;

                    }
                    currentElem = currentElem?.parentElement;
                }

                this.setDetailHeight(parentElem, detailElem);

                if (this.activeParentElem) {
                    //this.activeTimeline.reverse();
                    this.hideRow(this.activeParentElem);
                }
                //tl.play();
                this.activeTimeline = tl;
                this.activeParentElem = parentElem;
            }

            const currentPerson = document.querySelector(`.cc-people-item[data-id="${id}"]`);
            const plusIcon = currentPerson?.querySelector('.cc-plus-icon');
            const minusIcon = currentPerson?.querySelector('.cc-minus-icon');

            plusIcon?.classList.add('hidden');
            minusIcon?.classList.remove('hidden');
            

            this.activeDetailElem = detailElem;
            this.activePanelId = id;
        }
    }
      
    private setupEventListeners() {
        const peopleGrid = document.querySelector('.cc-people-grid');

        if (peopleGrid && !this.eventAttached) {
            this.eventAttached = true;

            peopleGrid?.addEventListener('click', (e) => {
                const elem: HTMLElement = e.target as HTMLElement;
                let parentElem: HTMLElement | null = null;
                let currentElem: HTMLElement | null | undefined = elem;

                while (parentElem === null) {
                    if (currentElem?.classList.contains('cc-people-item-button')) {
                        parentElem = currentElem;
                    }
                    currentElem = currentElem?.parentElement;

                    if (currentElem?.isEqualNode(peopleGrid)) {
                        break;
                    }
                }

                if (parentElem) {
                    if (this.activeButton && this.activeButton !== elem) {
                        this.activeButton.classList.remove('is-active');
                    }

                const targetId = parentElem?.getAttribute('data-target');
                    if (targetId) {
                        this.toggleActive(targetId);
                        elem.classList.toggle('is-active');
                        this.activeButton = elem;
                    }
                }
            });
        }
    }
}
