const HoverLinks = () => {
  const links = document.querySelectorAll('.cc-services-link');
  const linksImages = document.querySelectorAll('.cc-hoverlinks-image');
  let activeImage: Element | null = null;

  linksImages[0].classList.add('opacity-100');
  linksImages[0].classList.remove('opacity-0');

  const showImage = (id) => {
    const image = Array.from(linksImages).find(
      (link) => link.getAttribute('data-id') === id
    );

    if (image && image !== activeImage) {
      activeImage?.classList.remove('opacity-100');
      activeImage?.classList.add('opacity-0');

      image.classList.remove('opacity-0');
      image.classList.add('opacity-100');

      activeImage = image;
    }
  };

  Array.from(links).forEach((linkElem) => {
    const id = linkElem.getAttribute('data-id');

    linkElem.addEventListener('mouseover', () => {
      showImage(id);
    });
  });
};

export default HoverLinks;
