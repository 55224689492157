import gsap from "gsap";
import FullPage from './FullPageScroll';

export default function BannerAnim() {
    const banner = document.querySelector(".cc-banner");
    const preText = banner?.querySelector(".cc-pre-text");
    const postText = banner?.querySelector(".cc-post-text");
    const scrollArrow = banner?.querySelector('.scroll');

    if (preText) {
        gsap.set(preText, { x: "-50px" });
        gsap.to(preText, { x: "0px", opacity: 1, duration: 1, delay: 2 });
    }

    if (postText) {
        gsap.set(postText, { x: "50px" });
        gsap.to(postText, { x: "0px", opacity: 1, duration: 1, delay: 2 });
    }

    if (scrollArrow) {
        scrollArrow.addEventListener('click', () => {
            FullPage.goToNext();
        });
    }

}
