function CheckDevice() {
    return window.innerWidth >= 1024 ? 'md' : 'sm';
}

export default function Header() {

    const navbar = document.querySelector(".cc-site-header") as HTMLElement;
    const spot = document.querySelector(".spot") as HTMLElement;
 
    const handleScroll = (entries) => {
        const spotIsVisible = entries[0].isIntersecting;
        if (spotIsVisible) {
            navbar.classList.remove("overlay");
        }
        else {
            navbar.classList.add("overlay");
        }
    };
    const options = {
        root: null,
        rootMargin: "0px",
        threshhold: 0,
    };

    const observer = new IntersectionObserver(handleScroll, options);
    observer.observe(spot);


}