import gsap from 'gsap';

export default function Accordion(): void {
  const accordions = document.querySelectorAll('.cc-accordion');

  accordions.forEach((element) => {
    const header = element.querySelector('.cc-accordion-header');
    const content = element.querySelector('.cc-accordion-content');
    const timeline = gsap.timeline({ paused: true });

    timeline.from(content, { height: 0 });

    header?.addEventListener('click', () => {
      const isActive = header.classList.contains('is-active');
      header.classList.toggle('is-active');
      if (isActive) {
        timeline.reverse();
      } else {
        timeline.play();
      }
    });
  });
}
