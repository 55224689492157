export default function VideoModal() {
  const videoModalButtons = document.querySelectorAll('.cc-video-modal-button');
  const videoModal = document.querySelector('.cc-video-modal');
  const videoElem: HTMLVideoElement | null = document.querySelector(
    '.cc-video-modal-video'
  );
  const modalCloseButton = document.querySelector('.cc-video-modal-close');

  const toggleVideo = (videoSrc: string) => {
    videoModal?.classList.remove('hidden');

    if (videoElem) {
      videoElem.src = videoSrc;
    }
  };

  const closeModal = () => {
    videoModal?.classList.add('hidden');

    if (videoElem) {
      videoElem.src = '';
    }
  };

  Array.from(videoModalButtons).forEach((elem) => {
    const videoSrc = elem.getAttribute('data-video');

    if (videoSrc) {
      elem.addEventListener('click', () => {
        toggleVideo(videoSrc);
      });
    }
  });

  modalCloseButton?.addEventListener('click', closeModal);
}
