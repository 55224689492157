import 'core-js/stable';
import 'regenerator-runtime/runtime';

import '../styles/index.scss';
import Header from './Components/Header';
import Nav from './Components/Nav';
import PeopleFinder from './Components/PeopleFinder';
import FullPage from './Components/FullPageScroll';
import BannerAnim from './Components/BannerAnim';
import HoverLinks from './Components/HoverLinks';
import VideoModal from './Components/VideoModal';
import PeopleViewer from './Components/PeopleViewer';
import Accordion from './Components/Accordion';

let peopleViewer: PeopleViewer | null;

function CheckDeviceSize() {
    return window.innerWidth > 1024 ? 'md' : 'sm';
}

window.addEventListener('load', () => {
    Header();
    Nav();
    PeopleFinder();

    if (document.querySelector('.cc-accordion')) {
        Accordion();
    }

    if (document.querySelector('.cc-pre-text')) {
        BannerAnim();
    }

    if (document.querySelector('.cc-services-link')) {
        HoverLinks();
    }

    if (document.querySelector('.cc-video-modal-button')) {
        VideoModal();
    }

    if (document.querySelector('.cc-people-list')) {
        peopleViewer = new PeopleViewer(4);
    }

    if (document.querySelector('.cc-page-slider')) {
        FullPage.init();
    }    
});
