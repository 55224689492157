function CheckDevice() {
    return window.innerWidth >= 1024 ? 'md' : 'sm';
}

export default function Nav() {
    const navButton: HTMLElement = document.querySelector('.cc-nav-button') as HTMLElement;
    const navMenu = document.querySelector('.cc-nav-menu');
    const primaryNav: HTMLElement = document.querySelector('.cc-nav-menu-primary') as HTMLElement;;
    const secondaryNav = document.querySelector('.cc-nav-menu-secondary');
    const tertiaryNav = document.querySelector('.cc-nav-menu-tertiary');
    
    const subnavItemsElems = document.querySelectorAll('.cc-subnav-menu');
    const header = document.querySelector('header');
    const peopleFinder = document.querySelector('.cc-people-finder');
    const peopleFinderCloseIcon = document.querySelector('.cc-people-finder-close-icon');
    const peopleFinderButton = document.querySelector('.cc-people-finder-button');
    const peopleFinderIcon = document.querySelector('.cc-people-finder-icon');
    const navIcons = document.querySelector('.cc-nav-icons');

    let subnavItems = {};
    let activeSubMenu: Array<Element> = [primaryNav];

    subnavItemsElems.forEach((elem) => {
        subnavItems[elem.id] = elem;
    });

    const refresh = () => {
        if (activeSubMenu) {
            if (CheckDevice() === 'md') {
                activeSubMenu.forEach((elm) => {
                    elm.classList.remove('hidden');
                });
            } else {
                activeSubMenu.forEach((elm, i) => {
                    if (i < activeSubMenu.length - 1) {
                        if (!elm.classList.contains('hidden')) {
                            elm.classList.add('hidden');
                        }
                    }
                    
                });
            }
        }
    };

    const reset = () => {
        activeSubMenu.forEach((elm, i) => {
            if (i > 0) {
                if (!elm.classList.contains('hidden')) {
                    elm.classList.add('hidden');
                }
            }
            else {
                elm.classList.remove('hidden');
            }
        });
        activeSubMenu = [primaryNav];
    };

    const activateSubMenu = (targetElem: HTMLElement, linkedElement: HTMLElement) => {
        if (targetElem) {

            if (activeSubMenu && activeSubMenu.includes(targetElem)) {
                //pop until we reach target
                for (var i = activeSubMenu.length; i--;) {
                    if (!activeSubMenu[i].isEqualNode(targetElem)) {
                        activeSubMenu[i].classList.add('hidden');
                        activeSubMenu.pop();
                    }
                    else {
                        break;
                    }
                }
            }
            else {

                if (targetElem.parentElement?.isEqualNode(tertiaryNav)) {
                    if (activeSubMenu.length > 2) {
                        activeSubMenu[2].classList.add('hidden');
                        activeSubMenu.pop();
                    }
                    activeSubMenu.push(targetElem);
                    targetElem.classList.remove('hidden');
                } else {
                    //secondary nav
                    if (activeSubMenu.length > 2) {
                        activeSubMenu[2].classList.add('hidden');
                        activeSubMenu[1].classList.add('hidden');
                        activeSubMenu = [];
                        targetElem.classList.remove('hidden');
                        activeSubMenu.push(targetElem);
                    }
                    else {
                        if (activeSubMenu.length !== 1) {
                            activeSubMenu[1].classList.add('hidden');
                        }
                        activeSubMenu = [primaryNav];
                        targetElem.classList.remove('hidden');
                        activeSubMenu.push(targetElem);
                    }
                }
            }

        }
        else {
            //we hovered a link with no sub menu
            let linkedParent = linkedElement.parentElement as HTMLElement;
            while (linkedParent.tagName.toLowerCase() !== 'nav'
                && !linkedParent.isEqualNode(primaryNav) && !linkedParent.isEqualNode(secondaryNav)) {
                linkedParent = linkedParent!.parentElement!;
            }
            if (linkedParent!.tagName.toLowerCase() !== 'nav') {
                if (linkedParent.isEqualNode(primaryNav)) {
                    //kill second and third navs
                    activeSubMenu.forEach((elm, i) => {
                        if (i !== 0) {
                            elm.classList.add('hidden');
                        }
                    });
                    activeSubMenu = [primaryNav];
                }
                else {
                    if (activeSubMenu.length > 2) {
                        activeSubMenu[2].classList.add('hidden');
                        activeSubMenu.pop();
                    }
                }
            }
            
        }
    };

    const toggleMobSubNav = () => {
        if (activeSubMenu.length === 3) {
            primaryNav?.classList.add('hidden');
            secondaryNav?.classList.add('hidden');
            tertiaryNav?.classList.remove('hidden');
        } else if (activeSubMenu.length === 2) {
            primaryNav?.classList.add('hidden');
            secondaryNav?.classList.remove('hidden');
            tertiaryNav?.classList.add('hidden');
        } else {
            primaryNav?.classList.remove('hidden');
            secondaryNav?.classList.add('hidden');
            tertiaryNav?.classList.add('hidden');
        }

        applyScroll();

    };

    const watchHovers = () => {
        const primaryNavLinks = primaryNav?.querySelectorAll('a');

        if (primaryNavLinks) {
            Array.from(primaryNavLinks).forEach((nav) => {
                nav.addEventListener('mouseover', () => {
                    const targetId = nav.getAttribute('data-target');
                    if (targetId) {
                        activateSubMenu(subnavItems[targetId], nav);
                        applyScroll();
                    }
                });
            });

            const secondryNavLinks = secondaryNav?.querySelectorAll('a');

            if (secondryNavLinks) {
                Array.from(secondryNavLinks).forEach((nav) => {
                    nav.addEventListener('mouseover', () => {
                        const targetId = nav.getAttribute('data-target');
                        if (targetId) {
                            activateSubMenu(subnavItems[targetId], nav);
                        }
                    });
                });
            }
        }
    };

    const watchMobButtons = () => {
        const mobNavLinks = document?.querySelectorAll('.cc-nav-link-next-lvl');
        const mobPrevLinks = document?.querySelectorAll('.cc-nav-link-prev-lvl');

        if (mobNavLinks) {
            Array.from(mobNavLinks).forEach((nav) => {
                nav.addEventListener('click', () => {
                    const targetId = nav.getAttribute('data-target');
                    if (targetId) {
                        activateSubMenu(subnavItems[targetId], nav as HTMLElement);
                        toggleMobSubNav();
                    }
                });
            });
        }

        if (mobPrevLinks) {
            Array.from(mobPrevLinks).forEach((nav) => {
                nav.addEventListener('click', () => {
                    const targetId = nav.getAttribute('data-target');
                    if (targetId) {
                        activateSubMenu(subnavItems[targetId], nav as HTMLElement);
                        toggleMobSubNav();
                    }
                });
            });
        }
    };


    const ToggleNav = () => {
        const isActive = header?.classList.contains('nav-active');

        if (!isActive) {
            peopleFinder?.classList.add('hidden')
            navMenu?.classList.remove('hidden');
            navButton?.classList.add('is-active');
            header?.classList.remove('people-finder-active');
            header?.classList.add('nav-active');
            if (header?.classList.contains('overlay')) {
                header.setAttribute('data-overlay', 'on');
            }
            header?.classList.remove('overlay');
            //prevent window scrollbar when menu open
            document.documentElement.classList.add('overflow-y-hidden');
        } else {
            navMenu?.classList.add('hidden');
            navButton?.classList.remove('is-active');
            header?.classList.remove('nav-active');
            if (header?.hasAttribute('data-overlay')) {
                header?.classList.add('overlay');
                header.removeAttribute('data-overlay');
            }
            document.documentElement.classList.remove('overflow-y-hidden');
            reset();
        }

        peopleFinderCloseIcon?.classList.add('hidden')
        peopleFinderButton?.classList.remove('is-active');
        peopleFinderIcon?.classList.remove('hidden');
        navIcons?.classList.remove('cc-active-change');        

    };

    const applyScroll = () => {
        if (activeSubMenu) {
            const activeList = activeSubMenu[activeSubMenu.length - 1].querySelector('.cc-subnav-menu__list') as HTMLElement;

            if (activeList) {
                var rect = activeList.getBoundingClientRect();
                if (rect) {
                    if (rect.top + rect.height > window.innerHeight) {
                        if (activeList) {
                            activeList.style.height = (window.innerHeight - rect.top - 100).toString() + 'px';
                            activeList.classList.add('overflow-y-scroll');
                        }
                    }
                }
            }
        }
        
    }

    navButton?.addEventListener('click', ToggleNav);

    watchHovers();
    watchMobButtons();

    window.addEventListener('resize', () => {
        refresh();
    });

}