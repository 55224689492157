import gsap from 'gsap';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

class FullPage {
    header: HTMLElement | null = null;
    mainElem: HTMLElement | null = null;
    sliderElem: HTMLElement | null = null;
    sliderStatusElem: HTMLElement | null = null;
    sliderContainerElem: HTMLElement | null = null;
    pageStatusLines: HTMLElement[] = [];
    activeStatusLine: HTMLElement | null = null;
    sectionElems:  Array<HTMLElement> | null = null;
    activeSlide = 0;
    animating = false;
    private isRealScrollEvent: boolean = true;
    private slideEventFunc = this.slideHandler.bind(this);
    private resizeEventFunc = this.resizeHandler.bind(this);
    private eventListenersAttached: boolean = false;
    private scrollTimeoutFunc = this.scrollTimeoutHandler.bind(this);
    private lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
    private scrollTimer: any = null;

    constructor() {
        gsap.registerPlugin(ScrollToPlugin);
        // create necessary elements
        this.mainElem = document.querySelector('main');
        if (this.mainElem) {
            this.sectionElems = Array.from(this.mainElem?.querySelectorAll('section'));
        }

        this.sliderElem = document.querySelector('.cc-page-slider');
        this.sliderStatusElem = document.createElement('div');
        this.header = document.querySelector('header');
        this.sliderContainerElem = document.querySelector('.cc-page-slider-container');


        this.animScrollComplete = this.animScrollComplete.bind(this);
        this.animScrollStart = this.animScrollStart.bind(this);
        this.goToNext = this.goToNext.bind(this);
        this.goToPrev = this.goToPrev.bind(this);

    }

    private reset() {

    }

    setupElements() {
        if (this.mainElem && this.sliderElem && this.sliderStatusElem) {

            // get container element
            if (window.innerWidth > 1024 && !this.sliderContainerElem?.contains(this.sliderStatusElem)) {
                // setup page status element
                this.sliderStatusElem.classList.add('cc-page-slider-status');

                // add page status elements to page
                if (this.sliderContainerElem) {
                    this.sliderContainerElem.appendChild(this.sliderStatusElem);
                }

                // get an array of all page sections
                
                this.pageStatusLines = [];
                this.sliderStatusElem.replaceChildren();
                // for each page section
                if (this.sectionElems) {
                    this.sectionElems.forEach((elem, i) => {
                        // add the elements Y position to an array of scroll points
                        //this.sectionScrollPoints.push(elem.getBoundingClientRect().y);
                        // create a status line element
                        const statusLine = document.createElement('span');
                        statusLine.setAttribute('data-id', `${i}`);
                        this.sliderStatusElem?.appendChild(statusLine);
                        this.pageStatusLines.push(statusLine);
                    });
                }

                // set the first status line as active;
                this.pageStatusLines[0].classList.add('is-active');
                this.activeStatusLine = this.pageStatusLines[0];
            }
            else {
                this.removeSliderStatus();
            }
            // add extra scroll section for the footer
            const footer = document.querySelector('footer');
            if (footer) {
                this.sectionElems?.push(footer)
            }
        }
    }

    init() {
        this.setupElements();
        this.setupListeners();

        this.header?.classList.remove('overlay');
    }

    removeSliderStatus() {
        if (this.sliderContainerElem &&
            this.sliderStatusElem &&
            this.sliderContainerElem.contains(this.sliderStatusElem)) {
            this.sliderContainerElem.removeChild(this.sliderStatusElem);
        }
    }

    animScrollComplete(isReal:boolean) {
        this.animating = false;
        this.isRealScrollEvent = isReal ?? true;
        setTimeout(() => { this.addScrollEvent() }, 100);
    }

    animScrollStart() {
        document.removeEventListener('scroll', this.slideEventFunc);
        this.eventListenersAttached = false;
        this.animating = true;
    }


    updateStatusLine(newSlide: number) {
        if (this.activeStatusLine) {
            // remove active class from status line
            this.activeStatusLine.classList.remove('is-active');
        }

        if (newSlide === 0) {
            this.header?.classList.remove('overlay');
        } else {
            this.header?.classList.add('overlay');
        }

        const newStatusLine = this.pageStatusLines[newSlide];

        if (newStatusLine) {
            // add active class to status line
            newStatusLine.classList.add('is-active');
            this.activeStatusLine = newStatusLine;
        }
    }

    goToNext() {
        const atEnd = this.activeSlide + 1 === this.sectionElems?.length;
        if (this.mainElem) {

            if (!atEnd && !this.animating && this.sectionElems) {
                this.animating = true;
                this.activeSlide += 1;
                this.updateStatusLine(this.activeSlide);
                gsap.to(window, {
                    duration: .5,
                    scrollTo: {
                        y: this.sectionElems[this.activeSlide],
                        autoKill: false
                    },
                    ease: 'back.out(0.6)',
                    onComplete: this.animScrollComplete,
                    onStart: this.animScrollStart,
                    onCompleteParams: [true]
                });
            }
        }
    }

    goToPrev() {
        if (this.activeSlide && !this.animating && this.sectionElems) {
            this.animating = true;
            this.activeSlide -= 1;
            this.updateStatusLine(this.activeSlide);
            
            gsap.to(window, {
                duration: .5,
                scrollTo: {
                    y: this.sectionElems[this.activeSlide],
                    autoKill: false
                },
                ease: 'back.out(0.6)',
                onComplete: this.animScrollComplete,
                onCompleteParams: [true],
                onStart: this.animScrollStart
            });
        }
    }

    slideHandler(e: any) {
        if (!this.animating) {
            if (e instanceof WheelEvent) {
                this.isRealScrollEvent = false;
                if (e.deltaY < 0) {
                    this.goToPrev();
                } else if (e.deltaY > 0) {
                    this.goToNext();
                }
            } else if (e instanceof KeyboardEvent) {
                this.isRealScrollEvent = false;
                if (e.key === 'ArrowDown') {
                    this.goToNext();
                } else if (e.key === 'ArrowUp') {
                    this.goToPrev();
                }
            } else {
                if (this.isRealScrollEvent) {
                    console.log(window.scrollY);
                    if (this.scrollTimer !== null) {
                        clearTimeout(this.scrollTimer);
                    }
                    this.scrollTimer = setTimeout(this.scrollTimeoutFunc, 150);
                    this.isRealScrollEvent = true;
                }
            }
        }       
    }

    scrollTimeoutHandler(e: any) {
        this.sectionElems?.forEach(elem => {

            if (scrollY > elem.offsetTop && scrollY < elem.offsetTop + elem.getBoundingClientRect().height) {
                this.activeSlide = <number>this.sectionElems?.findIndex(i => i === elem);
            }
        });

        this.updateStatusLine(this.activeSlide);
       

    }

    resizeHandler(e: any) {
        if (window.innerWidth <= 1024) {
            //kill events
            if (this.eventListenersAttached) {
                document.removeEventListener('wheel', this.slideEventFunc);
                document.removeEventListener('keydown', this.slideEventFunc);
                document.removeEventListener('scroll', this.slideEventFunc);
                this.eventListenersAttached = false;
            }
            this.removeSliderStatus();
        }
        else {
            if (!this.eventListenersAttached) {
                this.addAllSlideEvents();
            }
            this.setupElements();
        }
    }

    addAllSlideEvents() {
        ['wheel', 'keydown', 'scroll']
            .forEach((eventType) =>
                this.addEvent(eventType)
        );
        this.eventListenersAttached = true;
    }

    addScrollEvent() {
        this.addEvent('scroll');
        this.eventListenersAttached = true;
    }

    addEvent(eventType) {
        document.addEventListener(
            eventType,
            this.slideEventFunc,
            false
        )
    }

    setupListeners() {
        if (window.innerWidth > 1024) {
            this.addAllSlideEvents();
        }
        window.addEventListener('resize', this.resizeEventFunc);

    }
}

export default (new FullPage);
